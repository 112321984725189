/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { toCamelCase } from "utils";

import allTrailsIcon from "assets/images/appIcons/allTrailsIcon.jpg";
import onXIcon from "assets/images/appIcons/onXIcon.jpg";
import stravaIcon from "assets/images/appIcons/stravaIcon.jpg";
import gaiaGPSIcon from "assets/images/appIcons/gaiagpsIcon.jpg";
import komootIcon from "assets/images/appIcons/komootIcon.jpg";
import topogpsIcon from "assets/images/appIcons/topogpsIcon.jpg";
import wikilocIcon from "assets/images/appIcons/wikilocIcon.jpg";

import trailsGPX from "assets/trails/trails.gpx";

const appGuidesData = [
  {
    title: `AllTrails`,
    id: `alltrails`,
    description: `Whether you hike, bike, run, or walk, AllTrails is your companion and guide to the outdoors. Find detailed reviews and inspiration from a community of trail-goers like you`,
    icon: allTrailsIcon,
    appStoreLink: `https://apps.apple.com/us/app/alltrails-hike-bike-run/id405075943`,
    playStoreLink: `https://play.google.com/store/apps/details?id=com.alltrails.alltrails`,
    externalGuideLink: `https://support.alltrails.com/hc/en-us/articles/360032749611-How-do-I-upload-a-file-to-AllTrails-`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your computer`,
      },
      {
        step: `02`,
        primaryText: `Log into your account on <a href='https://www.alltrails.com/' target='_blank' rel='noreferer'>AllTrails</a>`,
      },
      {
        step: `03`,
        primaryText: `Hover over 'Saved' in the top header and select 'Create map'`,
      },
      {
        step: `04`,
        primaryText: `Click ‘Upload from file’ in the left panel`,
      },
      {
        step: `05`,
        primaryText: `Click and drag the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file into the box or hit 'Browse' to open your file manager and select your file`,
      },
      {
        step: `06`,
        primaryText: `Click 'Next' to finish the upload`,
      },
      {
        step: `07`,
        primaryText: `You will see a 'Route uploaded' pop-up to confirm your file was uploaded successfully`,
      },
    ],
  },
  {
    title: `onX`,
    id: `onx`,
    description: `Meet the ultimate GPS mapping app for your outdoor pursuits. onX Backcountry lets you easily find the trails you're looking for—whether you’re seeking out new ski lines or trails to hike`,
    icon: onXIcon,
    appStoreLink: `https://apps.apple.com/us/app/onx-backcountry-snow-trail-gps/id1529165366`,
    playStoreLink: `https://play.google.com/store/apps/details?id=onxmaps.backcountry`,
    externalGuideLink: `https://onxbackcountry.zendesk.com/hc/en-us/articles/360057195972-How-to-Import-and-Export-Markups-Waypoints-Routes-Lines-Shapes-and-Tracks-in-onX-Backcountry`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your computer`,
      },
      {
        step: `02`,
        primaryText: `Log into your account on <a href='https://webmap.onxmaps.com/backcountry/login' target='_blank' rel='noreferer'>OnX</a>`,
      },
      {
        step: `03`,
        primaryText: `Click the My Content Button on the left side of the screen`,
      },
      {
        step: `04`,
        primaryText: `Click 'Import' at the top of the My Content Menu`,
      },
      {
        step: `05`,
        primaryText: `Click 'Select from computer' and use your computer's file explorer to select the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file`,
      },
      {
        step: `06`,
        primaryText: `To create a new Folder for your imported Markups, click the checkbox next to 'Import map data to a new folder'`,
      },
      {
        step: `07`,
        primaryText: `Click 'Import'`,
      },
      {
        step: `08`,
        primaryText: `The uploaded file is listed in the My Content Menu along with all of your other Markups. If your Markups do not show up after importing, refresh your web browser`,
      },
    ],
  },
  {
    title: `Strava`,
    id: `strava`,
    description: `Strava makes fitness tracking social. We house your entire active journey in one spot – and you get to share it with friends`,
    icon: stravaIcon,
    appStoreLink: `https://apps.apple.com/us/app/strava-run-bike-hike/id426826309`,
    playStoreLink: `https://play.google.com/store/apps/details?id=com.strava`,
    externalGuideLink: `https://support.strava.com/hc/en-us/articles/206811950-Uploading-Route-Files`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your computer`,
      },
      {
        step: `02`,
        primaryText: `Log into your account on <a href='https://www.strava.com/login/' target='_blank' rel='noreferer'>Strava</a>`,
      },
      {
        step: `03`,
        primaryText: `Select Dashboard from the top navigation menu on the Strava website`,
      },
      {
        step: `04`,
        primaryText: `Click My Routes > Create New Route`,
      },
      {
        step: `05`,
        primaryText: `Click the upload button highlighted below and choose the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file to create a route`,
      },
      {
        step: `06`,
        primaryText: `Once the file has been uploaded, you can adjust the waypoints on the map or edit your route preferences as necessary`,
      },
      {
        step: `07`,
        primaryText: `Select Save once you've finished`,
      },
    ],
  },
  {
    title: `Gaia GPS`,
    id: `gaia-gps`,
    description: `Adventure confidently with Gaia GPS. Discover new routes with worldwide maps. Find your way in the backcountry with leading navigation tools – no cell service needed`,
    icon: gaiaGPSIcon,
    appStoreLink: `https://apps.apple.com/us/app/gaia-gps-mobile-trail-maps/id1201979492`,
    playStoreLink: `https://play.google.com/store/apps/details?id=com.trailbehind.android.gaiagps.pro`,
    externalGuideLink: `https://help.gaiagps.com/hc/en-us/articles/115003639048-Import-GPX-and-KML-files-in-Gaia-GPS-for-iOS`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your device`,
      },
      {
        step: `02`,
        primaryText: `After adding the GPX file to your device's storage, tap the Saved icon in the bottom menu in the app`,
      },
      {
        step: `03`,
        primaryText: `Tap the 3 dots in the upper right-hand corner`,
      },
      {
        step: `04`,
        primaryText: `Tap Import File`,
      },
      {
        step: `05`,
        primaryText: `Locate the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file you downloaded on your device and tap it`,
      },
      {
        step: `06`,
        primaryText: `When the screen reads 'All imports complete' your file will be available in your saved folders`,
      },
    ],
  },
  {
    title: `Wikiloc`,
    id: `wikiloc`,
    description: `Discover millions of outdoor trails around the world. Choose between hiking, running, bike, MTB, kayak, ski or up to 80 different types of activities`,
    icon: wikilocIcon,
    appStoreLink: `https://apps.apple.com/us/app/wikiloc-outdoor-navigation-gps/id432102730`,
    playStoreLink: `https://play.google.com/store/apps/details?id=com.wikiloc.wikilocandroid`,
    externalGuideLink: `https://help.wikiloc.com/article/610-how-to-upload-a-trail-from-a-trail-file-gpx`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your computer`,
      },
      {
        step: `02`,
        primaryText: `Log into Wikiloc's website and sign in with your username`,
      },
      {
        step: `03`,
        primaryText: `From your profile, click on 'Upload Trails' at the top of the screen`,
      },
      {
        step: `04`,
        primaryText: `Choose the 'Upload file' option which says 'Select the file containing the GPS tracks and waypoints to upload'`,
      },
      {
        step: `05`,
        primaryText: `Choose the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file saved on your computer`,
      },
      {
        step: `06`,
        primaryText: `Make sure the track and the Waypoints you want to create are correctly selected. Click on 'Continue'`,
      },
      {
        step: `07`,
        primaryText: `Fill in the fields: Name, description, activity, technical difficulty, privacy setting, photos and videos. Click on 'Continue'`,
        secondaryText: `Remember that a quality description will increase your TrailRank and UserRank`
      },
      {
        step: `08`,
        primaryText: `You can add your Trail Buddies`,
        secondaryText: `You can also opt to share it on Social Networks`
      },
    ],
  },
  {
    title: `Komoot`,
    id: `komoot`,
    description: `Turn your next ride, hike, or run into an adventure with komoot. Get inspired by tapping into shared community knowledge and recommendations, then bring your adventures to life with the easy route planner`,
    icon: komootIcon,
    appStoreLink: `https://apps.apple.com/us/app/komoot-hike-bike-run/id447374873`,
    playStoreLink: `https://play.google.com/store/apps/details?id=de.komoot.android`,
    externalGuideLink: `https://support.komoot.com/hc/en-us/articles/360022834132-Export-and-import-GPS-files`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your computer`,
      },
      {
        step: `02`,
        primaryText: `Log into your account on <a href='https://account.komoot.com/signin' target='_blank' rel='noreferer'>Komoot</a>`,
      },
      {
        step: `03`,
        primaryText: `Click the plus icon (+) at the top of the menu bar`,
      },

      {
        step: `04`,
        primaryText: `Select Import a GPS file`,
      },
      {
        step: `05`,
        primaryText: `Click Upload File or drag and drop the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file into the highlighted window`,
      },
      {
        step: `06`,
        primaryText: `If necessary, change the sport and click Next`,
      },
      {
        step: `07`,
        primaryText: `If parts of your route deviate from known ways, you get the option to review the route. By clicking on Review Route the Route planner will open`,
        secondaryText: `Stick to original route: The navigation will be less accurate. Off-grid sections will be inserted at the points where the imported route deviates from known ways. Match route to known ways: Komoot will replan parts of your route along best alternative ways. The route won't be matched exactly, but navigation may be more reliable`
      },
      {
        step: `08`,
        primaryText: `Edit the Tour name and click Got it!`,
      },
    ],
  },
  {
    title: `TopoGPS`,
    id: `topogps`,
    description: `Makes from your device a complete GPS device with detailed topographic maps. Viewed maps will be stored on your device so that Topo GPS can also be used offline`,
    icon: topogpsIcon,
    appStoreLink: `https://apps.apple.com/us/app/topo-gps-topographic-maps/id578960575`,
    playStoreLink: `https://play.google.com/store/apps/details?id=uk.rdzl.topo.gps`,
    externalGuideLink: `https://www.topo-gps.com/ios/en/manual/html/routes/routes_import.html`,
    items: [
      {
        step: `01`,
        primaryText: `Download the <a href=${trailsGPX} download=${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx>Trails GPX File</a> and save it to your phone`,
      },
      {
        step: `02`,
        primaryText: `To import a route you could try to tap on the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file icon. Most often an app picker will show up. Sometimes you need to scroll through the list of apps or tap More to find ‘Topo GPS’. You then have to tap ‘Open with Topo GPS’ or ‘Copy to Topo GPS’ and the file will be imported in Topo GPS`,
      },
      {
        step: `03`,
        primaryText: `Alternatively you could tap Menu > More > Import file. Then the files app will be opened. Then you can browse through your files and select the '${toCamelCase(process.env.REACT_APP_COMMUNITY_NAME)}Trails.gpx' file to import`,
      },
      {
        step: `04`,
        primaryText: `If the routes were imported successfully, you will return to the main screen, and the map will be zoomed to the imported routes`,
        secondaryText: `The imported routes will be saved on your device and can be found back in the routes screen`
      },
    ],
  },
];

export default appGuidesData;