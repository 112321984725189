/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { HashLink as Link } from 'react-router-hash-link';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";

function AppIconCard({ icon, title, id }) {
  return (
    <Link to={`/#${id}`}>
      <MKBox lineHeight={1} p={2} textAlign={"center"}>
        <MKBox
          component="img"
          src={icon}
          alt={title}
          width="100%"
          my="auto"
          opacity={1}
          sx={{ borderRadius: 6 }}
        />
        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
          <MKTypography
            display="block"
            variant="5"
            fontWeight="bold"
            mt={2}
            mb={1.5}
          >
            {title}
          </MKTypography>
        </Stack>
      </MKBox>
    </Link>
  );
}

// Setting default props for the AppIconCard
AppIconCard.defaultProps = {
  color: "info",
};

// Typechecking props for the AppIconCard
AppIconCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AppIconCard;
