/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import LayersIcon from '@mui/icons-material/Layers';


// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import Navbar from "components/Navbar";

// Routes
import routes from "routes";

// Leaflet Maps
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import L from 'leaflet';
import React, { useState, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';

// Import marker icon images
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import markerShadowPng from "leaflet/dist/images/marker-shadow.png"

// Import GeoJSON data
import trailsGeoJSON from 'assets/trails/trails.json';

// Define your map layers
const mapLayers = {
  default: {
    url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
  },
  streets: {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
  },
  hybrid: {
    url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
    attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
  },
  satellite: {
    url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}",
    attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
  }
  // You can add more map types here
};

// Handle GeoJSON layer
function GeoJSONLayer({ setGeoJsonLayer, trailsLayerRef }) {
  const map = useMap();

  useEffect(() => {
    if (map && trailsGeoJSON && !trailsLayerRef.current) {
      const geoJsonLayer = L.geoJSON(trailsGeoJSON).addTo(map);
      setGeoJsonLayer(geoJsonLayer);  // Update the passed ref with the created layer
      map.fitBounds(geoJsonLayer.getBounds());
    }
  }, [map, setGeoJsonLayer, trailsLayerRef]);

  return null;
}

// Component for map control buttons
function MapControlButtons({ userPosition, trailsLayerRef, toggleMapType }) {
  const map = useMap();

  const centerMapOnUser = () => {
    if (userPosition && map) {
      map.flyTo(userPosition, 18);
    }
  };

  const centerMapOnTrails = () => {
    if (trailsLayerRef.current && map) {
      map.fitBounds(trailsLayerRef.current.getBounds());
    }
  };

  return (
    <Box position="fixed" bottom="30px" right="20px" sx={{ zIndex: 1000 }}>
      <Stack direction="column" spacing={2}>
        <IconButton variant="contained" color="primary" sx={{ backgroundColor: '#ffffff', borderWidth: 1, borderStyle: "solid", borderColor: '#78917b' }} onClick={centerMapOnUser}>
          <GpsFixedIcon />
        </IconButton>
        <IconButton variant="contained" color="secondary" sx={{ backgroundColor: '#ffffff', borderWidth: 1, borderStyle: "solid", borderColor: '#78917b' }} onClick={centerMapOnTrails}>
          <CenterFocusStrongIcon />
        </IconButton>
        <IconButton variant="contained" color="default" sx={{ backgroundColor: '#ffffff', borderWidth: 1, borderStyle: "solid", borderColor: '#78917b' }} onClick={toggleMapType}>
          <LayersIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}

function LiveMap() {
  const [userPosition, setUserPosition] = useState(null);
  const mapRef = useRef(null);
  const trailsLayerRef = useRef(null);
  const [mapType, setMapType] = useState("default");

  const setTrailsLayer = (layer) => {
    trailsLayerRef.current = layer;
  };

  const updateUserPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserPosition([position.coords.latitude, position.coords.longitude]);
      });
    }
  };

  const toggleMapType = () => {
    switch (mapType) {
      case "default":
        setMapType("hybrid");
        break;
      case "hybrid":
        setMapType("streets");
        break;
      case "streets":
        setMapType("satellite");
        break;
      case "satellite":
        setMapType("default");
        break;
      default:
        setMapType("default");
    }
  };

  useEffect(() => {
    updateUserPosition();
    const positionInterval = setInterval(updateUserPosition, 5000); // Update every 5 seconds

    return () => clearInterval(positionInterval); // Clear interval on component unmount
  }, []);

  const customMarkerIcon = L.icon({
    iconUrl: markerIconPng,
    shadowUrl: markerShadowPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  return (
    <Box>
      <MKBox position="fixed" top="0" zIndex="tooltip" width="100%">
        <Navbar
          routes={routes}
          action={{
            type: "internal",
            route: "/#downloads",
            label: "Downloads",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <MapContainer
            center={[39.068220022413776, -104.79549784598281]}
            zoom={13}
            style={{ height: "100vh", width: "100%" }}
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
              if (trailsGeoJSON) {
                trailsLayerRef.current = L.geoJSON(trailsGeoJSON).addTo(mapRef.current);
                mapRef.current.fitBounds(trailsLayerRef.current.getBounds());
              }
            }}
          >
            <TileLayer
              url={mapLayers[mapType].url}
              attribution={mapLayers[mapType].attribution}
            />
            {trailsGeoJSON && <GeoJSONLayer setGeoJsonLayer={setTrailsLayer} trailsLayerRef={trailsLayerRef} />}
            {userPosition && <Marker position={userPosition} icon={customMarkerIcon} />}
            <MapControlButtons userPosition={userPosition} trailsLayerRef={trailsLayerRef} toggleMapType={toggleMapType} />
          </MapContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LiveMap;
