export function toCamelCase(text) {
    return text
        // Replace any character that is not a letter or number with a space
        .replace(/[^a-zA-Z0-9]+/g, ' ')
        // Split the text into words
        .split(' ')
        // Capitalize the first letter of each word except the first word,
        // and join the words without spaces
        .map((word, index) =>
            index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join('');
}