/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import DownloadCard from "components/InfoCards/DownloadCard";

// Homepage page sections
import Counters from "pages/Homepage/sections/Counters";
import Information from "pages/Homepage/sections/Information";
import GuideSteps from "pages/Homepage/sections/GuideSteps";
import ContactCTA from "pages/Homepage/sections/ContactCTA";

// Homepage page components
import Download from "pages/Homepage/components/Download";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/heroBackground.jpg";
import texture from 'assets/images/topoTextureTileable.png';

// Trail Files
import trailsGPX from 'assets/trails/trails.gpx';
import trailsKML from 'assets/trails/trails.kml';
import trailsJSON from 'assets/trails/trails.json';

function Homepage() {
  return (
    <Box sx={{ backgroundImage: `url(${texture})`, backgroundRepeat: 'repeat', }}>
      <Navbar
        routes={routes}
        action={{
          type: "internal",
          route: "/#downloads",
          label: "Downloads",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={8} justifyContent="center" mx="auto"
            sx={{
              borderRadius: 8,
              padding: 4,
              backgroundColor: "rgba(0, 0, 0, 0.6)"
            }}>
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {`${process.env.REACT_APP_COMMUNITY_NAME ? process.env.REACT_APP_COMMUNITY_NAME.toUpperCase() : "COMMUNITY"} TRAILS`}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              sx={{ marginTop: 4 }}
            >
              Fast and easy Digital Trails so you can get off your computer and back outside
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <GuideSteps />
        <Container sx={{ mt: 6 }} id='downloads'>
          <Download />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <DownloadCard
                variant="gradient"
                color="info"
                icon="download"
                title="GPX"
                description="Apps that commonly use GPX files are Google Earth, Garmin MapSource, Garmin BaseCamp, Fatmap, RunGo, ARA GPX Viewer, BikeGPX, Strava, and Gaia GPS."
                file={trailsGPX}
                label="Download"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DownloadCard
                color="info"
                icon="download_for_offline"
                title="KML"
                description="Apps that commonly use KML files are Google Earth, Google Maps, AllTrails, Komoot, GPS Essentials, Viewranger, Locus Maps, and MapMyHike."
                file={trailsKML}
                label="Download"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DownloadCard
                color="info"
                icon="download_for_offline"
                title="GeoJSON"
                description="A GeoJSON file is a format for encoding geographical data structures using JavaScript Object Notation (JSON). This format is provided as a fallback that can be converted into other formats as needed."
                file={trailsJSON}
                label="Download"
              />
            </Grid>
          </Grid>
        </Container>
        <ContactCTA />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </Box>
  );
}

export default Homepage;
