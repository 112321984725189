/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Link } from 'react-router-dom';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import bgImage from "assets/images/downloadableBackground.jpg";

function Download() {

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <Stack direction={'column'}>
            <MKTypography variant="h6" color="white" fontWeight="bold">
              <TakeoutDiningIcon /> {" "}Trails Boxed Up To-Go
            </MKTypography>
            <MKTypography variant="h2" color="white" mb={1}>
              Downloadable Trails
            </MKTypography>
          </Stack>
          <MKTypography variant={{ xs: "body2", sm: "body1" }} color="white" opacity={0.8} mb={2}>
            While we do our best to provide guides for the most popular apps, we understand that you might want to try out a new app or have a software solution that we have not addressed. Below are downloadable versions of the trails in the three most commonly used formats. If you would like us to add another format, please contact us.
          </MKTypography>
          <MKTypography
            component={Link}
            to="/contact-us"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Contact Us <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox >
  );
}

export default Download;
