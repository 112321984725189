/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "components/RotatingCard";
import RotatingCardFront from "components/RotatingCard/RotatingCardFront";
import RotatingCardBack from "components/RotatingCard/RotatingCardBack";
import AppIconCard from "components/InfoCards/AppIconCard";

// Images
import bgFront from "assets/images/familyTrailWalking.jpg";
import bgBack from "assets/images/lostPhone.jpg";

import appGuidesData from "./data/appGuidesData";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center">
          <Grid item xs={12} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Your Trails
                    <br />
                    Your Apps
                  </>
                }
                description="All the MUI components that you need in a development have been re-design with the new look."
              />
              <RotatingCardBack
                image={bgBack}
                title="Missing Your App?"
                description="No problem, you can download our trail data in GPX or KML format and import it into your favorite app. If you would like us to add your app, please contact us."
                action={{
                  type: "internal",
                  route: "/#downloads",
                  label: "Downloadable Files",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7}>
            <MKTypography variant="h4" align="center" fontWeight="bold" mb={4}>
              Pick Your App To Get Started
            </MKTypography>
            <Grid container spacing={3}>
              {appGuidesData.map(({ title, id, icon }) => (
                <Grid item xs={6} sm={4} md={4} lg={4} xl={3} key={id}>
                  <AppIconCard
                    title={title}
                    id={id}
                    icon={icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
