/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Homepage page components
import Step from "pages/Homepage/components/Step";

// Data
import data from "pages/Homepage/sections/data/appGuidesData";
import { Stack } from "@mui/material";

function GuideSteps() {
  const renderData = data.map(({ title, id, description, icon, appStoreLink, playStoreLink, externalGuideLink, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title} id={id}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          {icon && (
            <MKBox
              component="img"
              src={icon}
              alt={title}
              width="50%"
              my="auto"
              opacity={1}
              sx={{ borderRadius: 8 }}
            />
          )}
          <Stack direction="row" justifyContent={'space-between'} spacing={2} sx={{ mt: 2 }}>
            <MKTypography variant="h3" fontWeight="bold" mb={1}>
              {title}
            </MKTypography>
            {appStoreLink && playStoreLink && (
              <MKBox>
                {appStoreLink && (
                  <Tooltip title="Download on the App Store">
                    <IconButton component='a' href={appStoreLink} target="_blank" rel="noreferer">
                      <AppleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {playStoreLink && (
                  <Tooltip title="Get it on Google Play">
                    <IconButton component='a' href={playStoreLink} target="_blank" rel="noreferer">
                      <AndroidIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </MKBox>
            )}
          </Stack>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Stack>
          {items.map(({ image, primaryText, secondaryText, step }) => (
            <Step image={image} primaryText={primaryText} secondaryText={secondaryText} step={step} key={primaryText} />
          ))}
        </Stack>
        {externalGuideLink && (
          <Stack direction="row" justifyContent="flex-end" mt={4}>
            <MKButton component="a" href={externalGuideLink} variant="outlined" fontWeight="regular" color="secondary" mb={1} pr={2}>
              View Full Guide
            </MKButton>
          </Stack>
        )}
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            App Guides
          </MKTypography>
          <MKTypography variant="body1" color="text">
            We have curated guides for the most popular GPS navigation and trail apps. Where possible, we have worked with app developers to include our trail information natively.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default GuideSteps;
