/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

function DefaultFooter() {
  const twitterShareURL = process.env.REACT_APP_TWITTER_SHARE_URL;
  const facebookShareURL = process.env.REACT_APP_FACEBOOK_SHARE_URL;
  const pinterestShareURL = process.env.REACT_APP_PINTEREST_SHARE_URL;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ ml: "auto", mb: 3 }}>
            <MKBox pb={6}>
              <Container>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                    <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                      Happy Trails!
                    </MKTypography>
                    <MKTypography variant="body1" color="text">
                      {`(psst... these outdoors look good on you)`}
                    </MKTypography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    my={{ xs: 5, lg: "auto" }}
                    mr={{ xs: 0, lg: "auto" }}
                    sx={{ textAlign: { xs: "center", lg: "right" } }}
                  >
                    <MKSocialButton
                      component="a"
                      href={twitterShareURL}
                      target="_blank"
                      color="twitter"
                      sx={{ m: 1 }}
                    >
                      <i className="fab fa-twitter" />
                      &nbsp;Tweet
                    </MKSocialButton>
                    <MKSocialButton
                      component="a"
                      href={facebookShareURL}
                      target="_blank"
                      color="facebook"
                      sx={{ m: 1 }}
                    >
                      <i className="fab fa-facebook" />
                      &nbsp;Share
                    </MKSocialButton>
                    <MKSocialButton
                      component="a"
                      href={pinterestShareURL}
                      target="_blank"
                      color="pinterest"
                      sx={{ m: 1 }}
                    >
                      <i className="fab fa-pinterest" />
                      &nbsp;Pin it
                    </MKSocialButton>
                  </Grid>
                </Grid>
              </Container>
            </MKBox>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography variant="button" fontWeight="regular">
              Sanctuary Pointe &copy; {new Date().getFullYear()} | All Rights Reserved
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DefaultFooter;
