/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Step({ image, step, primaryText, secondaryText, ...rest }) {
  const imageTemplate = (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      minHeight="10rem"
      sx={{
        overflow: "hidden",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
        },
      }}
      {...rest}
    >
      <MKBox
        component="img"
        src={image}
        alt={primaryText}
        width="100%"
        my="auto"
        opacity={1}
      />
    </MKBox>
  );

  return (
    <MKBox position="relative">
      {image && (
        imageTemplate
      )}
      {primaryText ? (
        <MKBox mt={1} ml={1} lineHeight={1}>
          <Stack direction="row">
            <MKBox mt={1} ml={1} lineHeight={1}>
              <MKTypography fontWeight="regular" color="secondary">
                {step}
              </MKTypography>
            </MKBox>
            <MKBox mt={1} ml={1} lineHeight={1}>
              <MKTypography >
                <span
                  dangerouslySetInnerHTML={{ __html: primaryText }}
                />
              </MKTypography>
              {secondaryText && (
                <MKTypography variant="button" fontWeight="regular" color="secondary">
                  <span
                    dangerouslySetInnerHTML={{ __html: secondaryText }}
                  />
                </MKTypography>
              )}
            </MKBox>
          </Stack>

        </MKBox>
      ) : null}
    </MKBox>
  );
}

// Setting default props for the ExampleCard
Step.defaultProps = {
  primaryText: "Step Example",
  step: "00",
};

// Typechecking props for the ExampleCard
Step.propTypes = {
  image: PropTypes.string,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  step: PropTypes.string.isRequired,
};

export default Step;
