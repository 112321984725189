const colors = {
  background: {
    default: "#f0f2f5",
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#475A3D",
    focus: "#3E4F33",
  },

  secondary: {
    main: "#5C7858",
    focus: "#526A4D",
  },

  info: {
    main: "#6C9273",
    focus: "#5F8164",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#9CBA88",
    focus: "#8CA875",
  },

  error: {
    main: "#748E69",
    focus: "#667A5B",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#344767",
    focus: "#2c3c58",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#4B6A46",
      state: "#3F573A",
    },
    secondary: {
      main: "#6E8971",
      state: "#617B5E",
    },
    info: {
      main: "#809A85",
      state: "#738B74",
    },
    success: {
      main: "#66BB6A",
      state: "#59A55C",
    },
    warning: {
      main: "#A7C79B",
      state: "#96B089",
    },
    error: {
      main: "#809C7A",
      state: "#72876C",
    },
    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },
    dark: {
      main: "#5E7561",
      state: "#526956",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#4C6A57",
      dark: "#435C4B",
    },
    twitter: {
      main: "#5D7E6A",
      dark: "#526E5C",
    },
    instagram: {
      main: "#547361",
      dark: "#496355",
    },
    linkedin: {
      main: "#618A73",
      dark: "#557964",
    },
    pinterest: {
      main: "#678D75",
      dark: "#5A7E67",
    },
    youtube: {
      main: "#6B9077",
      dark: "#5E8069",
    },
    vimeo: {
      main: "#719A7B",
      dark: "#648A6D",
    },
    slack: {
      main: "#77A37F",
      dark: "#699371",
    },
    dribbble: {
      main: "#7DAC83",
      dark: "#6E9875",
    },
    github: {
      main: "#83958A",
      dark: "#75847C",
    },
    reddit: {
      main: "#8A9E90",
      dark: "#7D8F82",
    },
    tumblr: {
      main: "#90A796",
      dark: "#829887",
    },
  },

  badgeColors: {
    primary: {
      background: "#8CA875",
      text: "#475A3D",
    },
    secondary: {
      background: "#A7C79B",
      text: "#5C7858",
    },
    info: {
      background: "#BDD4C1",
      text: "#6C9273",
    },
    success: {
      background: "#bce2be",
      text: "#4CAF50",
    },
    warning: {
      background: "#D3E2D0",
      text: "#9CBA88",
    },
    error: {
      background: "#C6D6C2",
      text: "#748E69",
    },
    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },
    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#475A3D",
    secondary: "#5C7858",
    info: "#6C9273",
    success: "#4CAF50",
    warning: "#9CBA88",
    error: "#748E69",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
